import React, { useEffect, useState, Fragment } from "react";
import "./index.scss";
export default function InfoView({ content, sendMessage }) {
  return (
    <Fragment>
      <div className="suggestText ">
        <div
          type="primary"
          ghost
          style={{ color: "#1677ff" }}
          onClick={() => {
            if (content?.return_type == "suggest") {
              sendMessage({
                content: `${content.data}`,
                role: "user",
                contentType: 0,
                actionType: "chat",
              });
            }
          }}
        >
          {content.data}
          {content?.return_type == "suggest" && (
            <i className="iconfont icon-jiantou_xiangyou"></i>
          )}
        </div>
      </div>
    </Fragment>
  );
}
