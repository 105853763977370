import React, { useEffect, useState, Fragment } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { getSearch } from "~/utils/jstools";
import { downloadFormula } from "~/api/formula-basis";
export default function GeneFormulaFile({
  userInfo,
  sId,
  sendMessage,
  formulaFiles,
}) {
  const equipmentMap = {
    1: {
      deviceName: "异木棉MC01",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotongliang.png",
    },
    3: {
      route: "/formulaList/ZZTConfigure",
      deviceName: "异木棉HT01",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotongliangshuiren.png",
    },
    5: {
      deviceName: "异木棉PC01",
      route: "/formulaList/PCConfigure",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotongliangguangcuihua.png",
    },
    6: {
      deviceName: "异木棉MC02",
      route: "/formulaList/HFCRConfigure",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotonglianggaopinyaohua.png",
    },
    7: {
      deviceName: "异木棉HT02",
      route: "/formulaList/ZZT2Configure",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotongliangshuirenb.png",
    },
    12: {
      deviceName: "异木棉HT02",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/ht03gaotongliangshuirenb.png",
    },
    11: {
      deviceName: "异木棉HT03",
      route: "/formulaList/ZZT3Configure",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/ht03gaotongliangshuirenb.png",
    },
    2: {
      deviceName: "木棉FS01",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/gaotongliangshuiren.png",
    },
    4: {
      deviceName: "木棉QD01",
      url: "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/image/liangzishebei.jpg",
    },
  };

  const [fileData, setFileData] = useState({});
  // 文件数据
  // useEffect(() => {
  //   console.log("🚀 ~ fileData:", fileData);
  // }, [fileData]);
  // 文件数据
  useEffect(() => {
    if (formulaFiles !== null && formulaFiles !== undefined) {
      setFileData(formulaFiles);
    }
  }, [formulaFiles]);
  const handleRedirect = (device) => {
    goFormaluList({ ...device });
  };

  const navigate = useNavigate();

  const goFormaluList = (item) => {
    // console.log("🚀 ~ goFormaluList ~ item:", item);
    // if (item.equipmentType == 5) return message.warning('该设备暂未开放')
    let deviceInfo = equipmentMap[item?.equipmentType];
    // console.log("🚀 ~ goFormaluList ~ deviceInfo:", deviceInfo);
    if (item?.id) {
      sessionStorage.setItem("deviceInfo", JSON.stringify(item));
      sessionStorage.setItem("flowInfo", JSON.stringify(item));
      navigate(`${deviceInfo?.route}?id=${item?.id}&runSimulateWarning=16`);
    } else {
      message.warning(
        "当前配方导入失败。失败原因：" + fileData?.err_msg || "未知"
      );
    }
  };

  const handleDownload = (item) => {
    if (!item?.id) {
      message.warning(
        "当前配方导入失败。失败原因：" + fileData?.err_msg || "未知"
      );
    } else {
      downloadFormula({
        basisId: item.id,
        encrypt: 1,
      }).then((res) => {
        let url = window.URL.createObjectURL(res); // 创建一个临时的url指向blob对象
        // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = `${item.deviceNumber}.ff`;
        a.click();
        // 释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      });
    }
  };

  return (
    <Fragment>
      <div
        className="chatText"
        style={{ marginBottom: !fileData?.id ? 0 : 10 }}
      >
        <div className="chatFlowItem robot" id={sId}>
          <div>
            <div>
              {fileData?.title && <h3>{fileData?.title}</h3>}
              {fileData?.id && (
                <div style={{ marginTop: "10px" }}>
                  <Button
                    type="link"
                    style={{ width: "200px", float: "left" }}
                    onClick={() => handleRedirect(fileData)}
                  >{`使用${
                    equipmentMap[fileData?.equipmentType]?.deviceName
                  }设备`}</Button>
                  <div className="devicePic">
                    <img
                      src={equipmentMap[fileData?.equipmentType]?.url}
                      alt=""
                    />
                  </div>

                  <Button
                    type="primary"
                    style={{ float: "left" }}
                    onClick={() => handleDownload(fileData)}
                  >
                    点击自动化下载文件
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!fileData?.id && (
        <div
          style={{
            padding: "10px 10px 10px 0 ",
            display: "inline-block",
            width: "100%",
            color: "#ff4d4f",
          }}
        >
          当前配方导入失败。失败原因：{fileData?.err_msg || "未知"}
          <Button
            type="primary"
            size="small"
            style={{
              marginLeft: "10px",
            }}
            danger
            ghost
            onClick={() => {
              sendMessage({
                content: `重新生成配方`,
                role: "user",
                contentType: 0,
                actionType: "ff",
              });
            }}
          >
            <i
              className="iconfont icon-liuzhuanjilu"
              style={{
                marginRight: "10px",
              }}
            ></i>
            重新生成配方
          </Button>
        </div>
      )}
    </Fragment>
  );
}
