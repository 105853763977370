import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { AutoComplete, Button, List, message } from "antd";
import "./index.scss";
// import ConversationList from "./modules/conversationList";
// import GeneFormula from "./modules/geneFormulaList";
import { store } from "~/store";
import HistoryView from "./modules/historyView";
import ChatField from "./modules/chatPanel";
import InputField from "./modules/inputField";
import HeaderField from "./modules/headerField";

import { getSysBasisData } from "~/api/common";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  aiDialogue,
  sendmail,
  createAiFormula,
  recipe,
} from "./utils/ai-pl-api.js";
import { doiList, chatData } from "./utils/mockData";
import useCommands from "~/components/chatAI/utils/hooks/useCommand.js";
import useWebsocket from "~/components/chatAI/utils/hooks/useWebsocket.js";
import { watch, getSnap } from "helux";
import { nanoid } from "nanoid";
import eventBus from "~/utils/hooks/eventBus";
import { PluginContext, CommandContext } from "../AIPL/utils/reactContext.js";
import { useTranslation } from "react-i18next";

let noOneByOneGenWordMap = ["ff", "user_idea", "info"];
let stepCount = 0;
let timer = null;

let dialogData = [];
let streamData = {};
let dialogId = "";
export default function ChatAIContainer({ setShowAI }) {
  const { t } = useTranslation();
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");

  const { wsData, wsAtomData, sendMessage, reconnect } = useWebsocket({
    url: `${process.env.REACT_APP_BASE_AIPL}/ws/copilot`, // 此参数为websocket地址
    verify: true, // 此参数控制是否有权限，请求该方法
  });
  // 处理语音录制和是否发送的状态
  const [aiStatus, setAiStatus] = useState({
    nav: "chat",
    isRecording: false,
    messageInfo: {
      msgId: "",
      isSending: false,
    },
    autoComplete: false,
  });
  store.subscribe(() => {
    const { sysStatusInfo } = store.getState();
    // console.log("🚀 ~ store.subscribe ~ sysStatusInfo:", sysStatusInfo);
    setAiStatus((prev) => {
      return {
        ...sysStatusInfo,
      };
    });
  });

  const plugins = useContext(PluginContext);
  const commands = useContext(CommandContext);
  // const { SendInput, SendText } = Plugins;
  const [mode, setMode] = useState("normal");
  const { goHome, goDeviceList } = useCommands();
  const [chatList, setChatList] = useState([]);
  const [chatInfo, setChatInfo] = useState({});
  useEffect(() => {
    console.log("🚀 ~ useEffect ~ chatList:", chatList);
  }, [chatList]);
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  };

  const onMessage = async (data) => {
    // console.log("🚀 ~ onMessage ~ data:", data.return_type, data.data);
    if (data.return_type == "id") {
      // 1、设置聊天记录的id
      setChatInfo({ ...chatInfo, id: data.data });
    }

    switch (data?.return_type) {
      case "start_stream":
        streamData = { data: "", return_type: data.data, id: nanoid() };
        dialogData.push(data);
        break;
      case "end_stream":
        streamData = {};
        break;

      case "id":
        setChatInfo({ ...chatInfo, id: data.data });
        // dialogData.push(data);
        break;
      case "ai_id":
        dialogId = data.data;
        store.dispatch({
          type: "sysStatusInfo",
          messageInfo: {
            msgId: data.data,
            isSending: true,
          },
        });
        setChatList((prev) => {
          let lastOne = prev[prev.length - 1];
          lastOne = {
            content: JSON.stringify([]),
            id: dialogId,
            role: "assistant",
          };
          prev[prev.length - 1] = lastOne;
          return [...prev];
        });
        // dialogData.push(data);
        break;
      case "end":
        // console.log("🚀 ~ onMessage ~ dialogData end:", dialogData);
        dialogData = [];
        store.dispatch({
          type: "sysStatusInfo",
          messageInfo: {
            msgId: "",
            isSending: false,
          },
        });
        break;

      default:
        let dialogDataRes = dialogData.filter(
          (item) => item.return_type !== "info"
        );
        // console.log("🚀 ~ onMessage ~ dialogDataRes:", dialogDataRes);
        // 非流式
        if (data?.return_type && !streamData?.return_type) {
          // 最后一个如何是info类型的数据，那么就要将新数据覆盖上去

          dialogDataRes.push(data);
        }

        // 流式数据处理
        if (data.return_type == streamData?.return_type) {
          streamData.data += data.data;
          if (dialogDataRes.length > 0) {
            dialogDataRes[dialogDataRes.length - 1] = streamData;
          } else {
            dialogDataRes.push(streamData);
          }
        }
        dialogData = dialogDataRes;
        break;
    }
    /**
     * {
     * return_type: "doi"
     * data: "你好"
     * }
     *
     */
    // console.log("🚀 ~ onMessage ~ dialogData:", dialogData, streamData);
    if (dialogData.length > 0) {
      // console.log("🚀 ~ onMessage ~ lenth 大于0", dialogData);
      setChatList((prev) => {
        let lastOne = prev[prev.length - 1];
        lastOne = {
          content: JSON.stringify(dialogData),
          id: dialogId || lastOne?.id,
          role: "assistant",
        };
        prev[prev.length - 1] = lastOne;
        // console.log("🚀 ~ setChatList ~ lastOne: 设置了吗", lastOne, prev);
        return [...prev];
      });
    }

    // 连接断开时，重新连接
    if (data?.status == 0) {
      reconnect();
      return null;
    }
    // AI返回数据为空时，不进行任何操作

    // console.log("🚀 ~ onMessage ~ streamContent:", streamContent);
    // setChatList((prev) => {
    //   if (prev.length > 0 && prev[prev.length - 1].id == userInfo?.id) {
    //     prev[prev.length - 1] = data;
    //   } else {
    //     prev.push(data);
    //   }
    //   return [...prev];
    // });
  };

  // 初始化页面后，重置状态
  useEffect(() => {
    openNewTopic();
  }, []);
  // useEffect(() => {
  //   // console.log('wsData, readyState,', wsData)

  //   // 接受到socket数据， 进行业务逻辑处理
  //   if (Object.keys(wsData).length !== 0) {
  //     // console.log('onmessage', wsData)
  //     onMessage(wsData);
  //   }
  // }, [wsData]);

  watch(
    () => {
      let data = wsAtomData.val.wsData;

      // console.log("wsAtomData", data);
      // 接受到socket数据， 进行业务逻辑处理
      if (Object.keys(data).length !== 0) {
        onMessage(data);
      }
    },
    () => [wsAtomData]
  );

  let commandsMap = {
    打开设备列表: goHome,
    返回首页: goHome,
    返回配方列表: goDeviceList,
    开始实验: () => {
      eventBus.$emit("controlZZTB", "开始实验");
    },
    继续实验: () => {
      eventBus.$emit("controlZZTB", "继续实验");
    },
    暂停实验: () => {
      eventBus.$emit("controlZZTB", "暂停实验");
    },
    结束实验: () => {
      eventBus.$emit("controlZZTB", "结束实验");
    },
    进入实验室模式: () => {
      eventBus.$emit("roomModeZZTB", "room");
    },
    返回实验界面: () => {
      eventBus.$emit("roomModeZZTB", "single");
    },
    帮我把专利发送到邮箱: () => {},
    帮我把实验报告发送到邮箱: () => {
      sendmail({
        // content: "",
        fileName:
          "FromDoi_Synthesis of photocatalysts and photocatalytic reduction of CO2 .pdf",
        fileUrl:
          "https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/ffv3/ai-download-files/FromDoi_Synthesis%20of%20photocatalysts%20and%20photocatalytic%20reduction%20of%20CO2%20.pdf",
        subject: "实验报告",
        to: "lijiagen@finefanta.com ",
      }).then((res) => {
        console.log("🚀 ~ sendmail ~ res:", res);
      });
    },
  };
  // 处理实验指令
  const doCommand = (text) => {
    let commandKeys = Object.keys(commandsMap);
    if (commandKeys.includes(text)) {
      // console.log(
      //   "🚀 ~ doCommand ~ text:",
      //   text,
      //   commandsMap,
      //   commandsMap[text]
      // );
      commandsMap[text] && commandsMap[text]();
    } else {
      // message.error("指令不存在");
    }
  };

  const talk2AI = (msg, ext) => {
    // console.log("🚀 ~ talk2AI ~ msg:", msg);
    let params = {
      id: chatInfo?.id || "",
      // isAI: !commandKeys.includes(msg.content),
      // ext: { recordId: chatInfo?.id || "" },
      ai_id: nanoid(),
      isAI: true,
      userId: userInfo?.id,
      ext: ext || {},
      orgNumber: userInfo?.orgNumber,
    };
    params.contentDo = [msg];

    sendMessage(params);
    let chatId = nanoid();
    // 消息发送后，处理收到消息前的模拟消息。
    setChatList((prev) => {
      // 1、 解析出content数据结构
      prev.push({
        id: nanoid(),
        role: "user",
        content: JSON.stringify(msg),
      });

      prev.push({
        id: chatId,
        role: "assistant",
        content: "[{}]",
      });
      return [...prev];
    });
    if (msg.actionType == "ff") {
      createAiFormula({
        contentDo: {
          content: "生成配方",
        },
      }).then((res) => {
        if (res.code == 0) {
          let data = res?.data || [];

          setChatList((prev) => {
            prev[prev.length - 1].content = JSON.stringify(data);

            return [...prev];
          });
          let params = [];
          if (data && data.length > 0) {
            params = data;
          }
          recipe({
            data: JSON.stringify(params),
            ai_id: nanoid(), // 对话id
            id: chatInfo?.id, // 会话id
          });

          // message.success("生成成功");
        } else {
          message.error(res.msg);
        }
      });
    }

    store.dispatch({
      type: "sysStatusInfo",
      messageInfo: {
        msgId: chatId,
        isSending: true,
      },
    });
  };
  // 用permise 实现异步延时
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  let msgObserver = useCallback(
    (messages) => {
      // console.log("🚀 ~ msgObserver ~ message:", messages, chatList);
      // let List = chatList;
      let newMessages = false;

      // if (message.content.includes("钙钛矿")) {
      //   message.content = "钙钛矿";
      // }
      switch (messages.content) {
        case "当前实验进度":
          // console.log("当前实验进度");
          let res = {
            content: [
              {
                return_type: "chat",
                data: `当前实验进度为39%，目前已完成所有移液动作，试管区K1号瓶子以1：11的比例装入水和四氯化钛，试管区2号瓶子以1：9的比例装入水和NH3·H2O，试管区K3号瓶子装入3.2ml的水和乙醇混合液体。正在进行加热反应。\n
原料统计：四氯化钛3.4ml，NbCl5原料1.2ml，水0.8ml\n
用电统计：目前已使用12kw/h，预计完成实验需耗电20kw/h`,
              },
            ],
          };
          newMessages = {
            aiDialogueId: "18471112675sd285524349",
            id: nanoid(),
            reqContent:
              '{"content":"' +
              messages.content +
              '","role":"user","contentType":0}',
            // resContent: JSON.stringify(res),
          };
          break;

        default:
          break;
      }

      // sleep(2000).then(() => {
      //   // console.log("🚀 ~ sleep ~ List:", List);
      //   if (newMessages) {
      //     setChatList((predata) => {
      //       let oldList = [...predata];
      //       return [...oldList, newMessages];
      //     });
      //   }
      // });
      // console.log("🚀 ~ ChatAIContainer ~ chatList:", chatList, newMessages);
      return newMessages;
    },
    [chatList, setChatList]
  );

  // 发送语音  contentType: 0, // 输入类型， 0：文本 1：文件 2：图片 3：语音 4：视频
  const sendMessageFn = useCallback(
    (messages, ext) => {
      // console.log("sendText------", messages, chatList, aiStatus);
      if (aiStatus?.messageInfo?.isSending) {
        return message.warning("请先等本次内容输出完成后再点击！");
      }

      let sortWord = msgObserver(messages);
      // console.log("🚀 ~ ChatAIContainer ~ sortWord:", sortWord);
      if (sortWord) return;
      // let lastData = [...chatList, message];
      // // 添加新的用户User的对话记录
      // setChatList(lastData);
      // 处理指令
      doCommand(messages?.content);
      // 与AI对话
      talk2AI(messages, ext);
    },
    [chatList, aiStatus]
  );

  // 开始自动化生成
  const [isAutoComplete, setIsAutoComplete] = useState(false);

  const autoSendMessage = useCallback(
    (content) => {
      sendMessageFn(content);
    },
    [sendMessageFn]
  );
  const startAutoComplete = () => {
    if (isAutoComplete) return;
    stepCount = 0;

    let steps = ["开始自动运行流程", "idea:异质结光催化剂", "生成配方"];
    timer = setInterval(() => {
      if (stepCount < steps.length) {
        autoSendMessage({ content: steps[stepCount] });
        stepCount++;
      } else {
        stepCount = 0;
        stopAutoComplete();
      }
    }, 5000);
  };
  // 停止自动化生成
  const stopAutoComplete = () => {
    clearInterval(timer);
  };

  // 维护AI聊天的状态：是否录音，是否发送, 是否自动完成
  useEffect(() => {
    eventBus.$on("AIStatus", (obj) => {
      // console.log("🚀 ~ eventBus.$on ~ obj:", obj);

      // if (obj?.messageInfo?.writedText) {
      //   setChatList((predata) => {
      //     let oldList = [...predata];

      //     let lastItem = oldList[oldList.length - 1];
      //     let lastItemContent = JSON.parse(lastItem?.resContent);
      //     lastItemContent.content = obj?.messageInfo?.writedText;
      //     oldList[oldList.length - 1].resContent =
      //       JSON.stringify(lastItemContent);
      //     // console.log("🚀 ~ setChatList ~ oldList:", oldList);
      //     return [...oldList];
      //   });
      // }
      store.dispatch({
        type: "sysStatusInfo",
        ...obj,
      });
    });
    // console.log("🚀 ~ evAIStatusj少时诵诗书:", eventBus.eventList);
    return () => {
      eventBus.$off("AIStatus");
    };
  }, [aiStatus, chatList]);

  // const [streamText, setStreamText] = useState("");
  // useEffect(() => {
  //   const socket = new WebSocket("ws://localhost:8080");
  //   // console.log("🚀 ~ useEffect ~ socket:", socket);
  //   socket.onmessage = (event) => {
  //     // console.log("🚀 ~ event:", event);
  //     setStreamText((predata) => {
  //       let predatas = predata;
  //       return predatas + event.data;
  //     });
  //   };
  // }, []);
  // 接收系统 生成的消息

  // 显示历史记录
  const showHistory = (data) => {
    if (data?.id) {
      setChatInfo({ id: data?.id });
      aiDialogue({ id: data?.id }).then((res) => {
        if (res.code == "0") {
          setChatList(res.data?.aiDialogueDetails);
        }
        // console.log("🚀 ~ aiDialogue ~ res:", res);
      });
    }
    // eventBus.$emit(
    //   "genChatItem",
    //   `钙钛矿innovation配方使用了四氯化钛、NH3·H2O、NbCl5、水和乙醇原料。`
    // );
    let nav = aiStatus?.nav === "history" ? "chat" : "history";
    if (data?.id) {
      nav = "chat";
    }
    // console.log("🚀 ~ showHistory ~ nav:", aiStatus, data, nav);
    store.dispatch({
      type: "sysStatusInfo",
      nav: nav,
    });
  };

  // 打开新话题
  const openNewTopic = () => {
    store.dispatch({
      type: "sysStatusInfo",
      nav: "chat",
      isRecording: false,
      messageInfo: {
        msgId: "",
        isSending: false,
      },
      autoComplete: false,
    });
    setChatInfo({});
    setChatList([]);
  };
  // 切换话题
  const changeTopic = (info) => {
    // console.log("🚀 ~ changeTopic ~ f:", info);
    // setChatInfo(info);
    openNewTopic();
  };
  const [basisData, setBasisData] = useState({});
  useEffect(() => {
    getSysBasisData().then((res) => {
      // console.log("🚀 ~ getSysBasisData ~ res:", res)
      if (res.code == 0) {
        document.title = res?.data.sysName || t("幻爽实验控制系统");
        setBasisData(res.data);
      }
    });
  }, []);
  // const diff = dmp.diff_main("dogs bark", "cats bark");
  return (
    <div className="ChatAIContainer">
      <HeaderField
        basisData={basisData}
        setShowAI={setShowAI}
        showHistory={showHistory}
        openNewTopic={openNewTopic}
      ></HeaderField>
      {aiStatus.nav === "history" && (
        <div className="historyField">
          <HistoryView showHistory={showHistory}></HistoryView>
        </div>
      )}
      <div className="layoutCenter">
        <ChatField
          basisData={basisData}
          chatList={chatList}
          sendMessage={sendMessageFn}
          aiStatus={aiStatus}
          showHistory={showHistory}
        ></ChatField>

        <InputField
          scrollToAnchor={scrollToAnchor}
          aiStatus={aiStatus}
          sendMessage={sendMessageFn}
          changeTopic={changeTopic}
          openNewTopic={openNewTopic}
        ></InputField>
      </div>
    </div>
  );
}
