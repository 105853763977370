import React, { useEffect, useState, Fragment } from "react";
import { Button, message, Card, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { getSearch } from "~/utils/jstools";
import { downloadFormula } from "~/api/formula-basis";
import "./index.scss";
export default function AuthorArticleView({ content, sendMessage }) {
  const navigate = useNavigate();

  const goFormaluList = (doi) => {
    window.open(`https://${doi}`);
  };

  const cardBoxRef = React.useRef();
  const handleLeft = (type) => {
    let left = cardBoxRef.current.scrollLeft;

    if (type === "right") {
      left += 428;
      // 让cardBoxRef 的scrollLeft = left
      cardBoxRef.current.scrollLeft = left;
      // cardBoxRef.current?.style.scrollLeft = left
    } else {
      left -= 428;
      cardBoxRef.current.scrollLeft = left;
    }

    // cardListRef.current?.scrollLeft += 200;
  };

  return (
    <Fragment>
      <div className="authorArticleView">
        {content?.data?.field && (
          <div className="title">
            <span style={{ fontWeight: "bold" }}>{content?.data?.field}</span>
            <span>{content?.data?.comment}</span>
            {/* <Button type="link" onClick={() => setShowJournal(false)}>
            收起
          </Button> */}
          </div>
        )}

        <div className="journalBox" ref={cardBoxRef}>
          <div
            className="btnL iconfont icon-arrow-right-line"
            onClick={() => handleLeft("left")}
          ></div>
          <div
            className="btnR iconfont icon-arrow-right-line"
            onClick={() => handleLeft("right")}
          ></div>

          <div className="journalList">
            {content?.data?.list.map((item, index) => {
              return (
                <div
                  className="journalItem"
                  onClick={() =>
                    sendMessage({
                      content: `${item?.doi}`,
                      role: "user",
                      contentType: 0,
                      actionType: "",
                      // actionType: "innovation_article",
                    })
                  }
                >
                  <p className=" itemTitle" title={item?.title}>
                    {item?.title}
                  </p>
                  {item?.correlation && (
                    <p
                      className="longTextShowOneLine "
                      title={item?.correlation}
                    >
                      {item?.correlation}
                    </p>
                  )}
                  {item?.score && (
                    <p className="longTextShowOneLine " title={item?.score}>
                      {item?.score}
                    </p>
                  )}
                  {/* <p className="longTextShowOneLine">相识度：90%</p> */}
                  <div
                    target="blank"
                    className={`btnLink longTextShowOneLine ${
                      item?.link ? "underline" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item?.link) goFormaluList(item?.link);
                    }}
                  >
                    {item?.doi}
                  </div>
                  {item?.journal && <div>{item?.journal}</div>}
                </div>
              );
            })}
          </div>
        </div>

        {content?.data?.button && (
          <Button
            type="primary"
            ghost
            size="small"
            style={{ color: "#1677ff", marginBottom: 10, marginTop: 10 }}
            onClick={() =>
              sendMessage({
                content: `${content?.data?.button}: ${content?.data?.field}`,
                role: "user",
                contentType: 0,
                actionType: "",
                // actionType: "innovation_article",
              })
            }
          >
            {/* {sitem.data} */}
            {content?.data?.button}
            {/* <i className="iconfont icon-jiantou_xiangyou"></i> */}
          </Button>
        )}
      </div>
    </Fragment>
  );
}
