import request from "~/api/modules/request.js";

// 接口格式规范 - 技术经理负责检查下列接口是否按照规范实施
// （get请求格式）
// export function nonce(params) {
//   return request({
//     url: '/public/login/digest/nonce.do',
//     method: 'get'
//   })
//   params:params,
// }

// （post请求格式）
// export function nonce(data) {
//   return request({
//     url: '/public/login/digest/nonce.do',
//     method: 'post',
// data:data,
//   })
// }
// 停止实例
export function sendAI(data) {
  return request({
    url: "/common/aiDialogue/send",
    method: "post",
    data: data,
  });
}

// 生成配方接口
export function createAiFormula(data) {
  return request({
    url: "/task/formula-device-execute/createAiFormula",
    method: "post",
    data: data,
  });
}

// 生成配方接口
export function recipe(data) {
  return request({
    url: "/ai/copilot/recipe",
    method: "post",
    data: data,
  });
}
// 当前对话所有数据;
export function aiDialogue(data) {
  return request({
    url: `/ai/copilot/detail`,
    method: "post",
    data,
  });
}
// 发送邮件;
export function sendmail(data) {
  return request({
    url: `/common/aiDialogue/sendmail`,
    method: "post",
    data,
  });
}

// 分页对话;
export function getHistoryData(data) {
  return request({
    url: `/ai/copilot/history`,
    method: "post",
    data: data,
  });
}

//下载文件
export function aiDialogueDownload(detailId) {
  return request({
    url: `/common/aiDialogue/${detailId}`,
    method: "post",
    responseType: "blob",
  });
}

//修改原来的数据
export function saveDetail(data) {
  return request({
    url: `/ai/copilot/edit`,
    method: "post",
    data,
  });
}

export function history(data) {
  return request({
    url: `/ai/copilot/history`,
    method: "get",
    data,
  });
}
