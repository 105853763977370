import React, { useState, useEffect, Fragment } from "react";
import { Button, Flex, message } from "antd";
import { nanoid } from "nanoid";

import {
  downloadFile,
  createAiFormula,
  recipe,
} from "../../../utils/ai-pl-api";
import { symbolFlow } from "../markdown/text";
import styles from "./index.scss";
export default function OperaButtons({
  sitem,
  msgContent,
  setDownloadFile,
  currentId,
  sendMessage,
  sindex,
  isEdit,
  setIsEdit,
  currentMsg,
  aiStatus,
}) {
  const returnTypeMap = {
    // author_article: ["先进性分析"],
    //普通对话
    chart: [],
    //论文信息
    info: [],
    //专利
    patent: [],
    //论文信息中的实验
    user_idea: ["提交"],
    //方向总结
    summary: ["思路创新"],
    //方向总结中单独的idea
    innovation_idea: ["创新方法"],
    //方法信息
    method: ["修改实验方法", "论文写作", "专利写作", "生成配方"],
    //文章信息
    // old_article: ["编辑", "下载", "更新表征结果数据", "添加引用"],
    //润色后的文章信息
    "new-article": ["编辑", "下载", "更新表征结果数据", "添加引用", "更新论文"],
    new_article: ["编辑"],
    whole_new_article: [
      "下载",
      "更新表征结果数据",
      "添加引用",
      "更新论文",
      "使用更强大的AI写作功能",
    ],
    old_article: ["编辑"],
    whole_article: [
      "下载",
      "更新表征结果数据",
      "添加引用",
      "更新论文",
      "使用更强大的AI写作功能",
    ],
    reference: ["编辑"],
    //实验表征描述
    result_data: ["更新表征结果数据"],
  };
  let primaryGhost = { type: "primary", danger: false, ghost: true };
  const [btnArr, setBtnArr] = useState([]);
  const [articleStr, setArticleStr] = useState("");
  // const [isEdit, setIsEdit] = useState(false);

  const btnTypeMap = {
    修改实验方法: primaryGhost,
    编辑: primaryGhost,
    更新表征结果数据: primaryGhost,
  };

  const btnToContentType = {
    思路创新: "innovation_idea",
    提交: "summary",
    论文写作: "old_article",
    专利写作: "patent",
    生成配方: "ff",
    创新方法: "method",
    添加引用: "reference",
    更新表征结果数据: "result_data",
    更新论文: "new_article",
  };

  useEffect(() => {
    // tempMsg[index].data
    if (sitem.return_type) {
      setBtnArr(returnTypeMap[sitem.return_type] || []);
      let editArr = [...isEdit];
      editArr[sindex] = false;
      setIsEdit(editArr);
    }
  }, [sitem]);

  useEffect(() => {
    // let str = "";
    // currentMsg.forEach((element) => {
    //   str += element.data;
    // });
    // setArticleStr(str);
  }, [currentMsg]);

  const handleSendMsg = (item) => {
    // console.log("🚀 ~ handleSendMsg ~ item:", sitem, item);
    let deleJint;
    let title;

    switch (sitem.return_type) {
      case "innovation_idea":
        deleJint = sitem.data.split("###");
        break;
      case "user_idea":
        deleJint = sitem.data.split("###");
        break;

      default:
        deleJint = sitem.data.split("#");
        break;
    }

    if (deleJint[1]) {
      title = deleJint[1].split("\n")[0];
    } else {
      title = sitem.data;
    }
    if (["whole_article", "whole_new_article"].includes(sitem.return_type)) {
      //  需要扩展字段：article
      sendMessage(
        {
          content: `${item}`,
          role: "user",
          contentType: 0,
          actionType: btnToContentType[item],
        },
        { article: currentMsg }
      );
    } else if (["method"].includes(sitem.return_type)) {
      deleJint = sitem.data.split("###");
      title = deleJint[1].split("\n")[0];
      // 将title里的：去掉
      title = title.replace(/：/g, "");
      title = title.replace(/:/g, "");

      let params = {
        content: `${item}：${title}`,
        role: "user",
        contentType: 0,
        actionType: btnToContentType[item],
      };
      sendMessage(params, { symbolFlow: symbolFlow });
    } else if (
      ["method", "innovation_idea", "old_article", "patent"].includes(
        sitem.return_type
      )
    ) {
      deleJint = sitem.data.split("###");
      title = deleJint[1].split("\n")[0];
      // 将title里的：去掉
      title = title.replace(/：/g, "");
      title = title.replace(/:/g, "");
      // 需要title和扩展字段：article
      sendMessage(
        {
          content: `${item}：${title}`,
          role: "user",
          contentType: 0,
          actionType: btnToContentType[item],
        },
        { article: deleJint[1] }
      );
    } else {
      // 需要title
      sendMessage({
        content: `${item}：${title}`,
        role: "user",
        contentType: 0,
        actionType: btnToContentType[item],
      });
    }
  };

  const handleClick = (item) => {
    // console.log("🚀 ~ handleClick ~ item:", item);
    // console.log(aiStatus);
    // if (aiStatus.messageInfo.isSending) {
    //   message.warning("请先等本次内容输出完成后再点击！");
    //   return;
    // }
    let editArr = [...isEdit];

    switch (item) {
      case "提交":
        if (sitem.return_type == "user_idea") {
          handleSendMsg(item);
        } else {
          editArr[sindex] = false;
          setIsEdit(editArr);
        }
        // console.log(sindex);

        break;
      case "思路创新":
        sendMessage({
          content: `${item}`,
          role: "user",
          contentType: 0,
          actionType: btnToContentType[item],
        });
        break;
      case "使用更强大的AI写作功能":
        window.open("https://uat-c.finefanta.com/#/?dtype=1", "_blank");
        break;
      case "生成配方":

      // break;

      case "论文写作":
      // break;
      case "专利写作":
      // break;
      case "创新方法":

      case "更新表征结果数据":
      // break;
      case "更新论文":
      // break;
      case "添加引用":
        handleSendMsg(item);
        break;
      case "修改实验方法":
      case "编辑":
        editArr[sindex] = true;
        // console.log(editArr);

        setIsEdit(editArr);
        break;

      case "下载":
        setDownloadFile();

        break;

      default:
        break;
    }
  };

  const btnText = (item) => {
    return item == "修改实验方法" || item == "编辑"
      ? isEdit[sindex]
        ? "提交"
        : item
      : item;
  };

  return (
    <Fragment>
      <div className={styles.operaButtonsMain}>
        {btnArr.map((item) => {
          return (
            <Button
              key={nanoid()}
              type={
                btnTypeMap[btnText(item)]?.type
                  ? btnTypeMap[btnText(item)].type
                  : "primary"
              }
              danger={btnTypeMap[btnText(item)]?.danger}
              ghost={btnTypeMap[btnText(item)]?.ghost}
              className={styles.btn}
              onClick={() => {
                // console.log("🚀 ~ item:", item);
                handleClick(btnText(item));
              }}
            >
              {btnText(item)}
              {item === "使用更强大的AI写作功能" && (
                <i
                  class="iconfont icon-jiantou_xiangyou"
                  style={{ fontSize: 12, marginLeft: 10 }}
                ></i>
              )}
            </Button>
          );
        })}
      </div>
    </Fragment>
  );
}
