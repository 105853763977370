import { useState, useEffect } from "react";
import { Button, List } from "antd";
import "./index.scss";

import eventBus from "~/utils/hooks/eventBus";
import { getHistoryData } from "../../utils/ai-pl-api.js";
// const userInfo = window.localStorage.getItem("userInfo") || "{}";
// let token = JSON.parse(userInfo).token;
function HistoryView({ scrollToAnchor, sendMessage, showHistory }) {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    getHistoryData({
      orgNumber: userInfo?.orgNumber,
      pageNum: 1,
      pageSize: 1000,
      userId: userInfo?.id,
    }).then((res) => {
      // console.log("🚀 ~ aiDialogue ~ res:", res);
      if (res.code === "0") {
        // res.data?.records 倒序排列
        setHistoryData(res.data?.records.reverse());
      }
    });
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={historyData}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            onClick={() => showHistory(item)}
            title={
              <p>
                {item.name}
                <i
                  className="iconfont icon-arrow-right-line"
                  style={{ float: "right" }}
                ></i>
              </p>
            }
            style={{ cursor: "pointer" }}
          />
        </List.Item>
      )}
    />
  );
}

export default HistoryView;
