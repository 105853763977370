import { useState, useEffect, Fragment, useContext, useCallback } from "react";
import { Button, Input, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import eventBus from "~/utils/hooks/eventBus";
import { nanoid } from "nanoid";
import Markdown from "./markdown";
import OperaButtons from "./operaButtons";
import "./index.scss";
import MarkdownEditor from "./markdownEditor";

import { PluginContext } from "~/components/AIPL/utils/reactContext.js";

import { saveDetail } from "../../utils/ai-pl-api";
import GeneFormulaFile from "./geneFormulaFile";
import JournalView from "./journalView"; // 期刊视图
import AuthorArticleView from "./authorArticleView"; // 期刊视图
import InfoView from "./infoView";
import WarningView from "./warningView";
import SuggestView from "./suggestView";

let cacheMsg = [];
export default function CommonView({
  msg,
  sId,
  wholeItem,
  sendMessage,
  aiStatus,
  showHistory,
}) {
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  // console.log("🚀 ~ IdeaField ~ msg:", msg);
  const [isEdit, setIsEdit] = useState([]);
  const [currentMsg, setCurrentMsg] = useState(msg);
  // useEffect(() => {
  //   console.log(
  //     "🚀 ~ useEffect ~ isEdit: currentMsg:22222",
  //     isEdit,
  //     currentMsg
  //   );
  // }, [isEdit, currentMsg]);
  // let [cacheMsg, setCacheMsg] = useState([]);

  const Plugins = useContext(PluginContext);
  const [isClickEdit, setIsClickEdit] = useState(false);
  const [formulaFiles, setFormulaFiles] = useState();

  useEffect(() => {
    isClickEdit || setCurrentMsg(msg);
  }, [msg]);
  // useEffect(() => {
  //   console.log("🚀 ~ useEffect ~ cacheMsg:", cacheMsg);
  // }, [cacheMsg]);
  // const [resContent, setResContent] = useState(msg?.content);

  // 下载论文文章
  const setDownloadFile = (index, content) => {
    let fileData = "";
    currentMsg.forEach((item, i) => {
      fileData += item?.data;
    });
    fileData = fileData.replace(/###/g, "\n");
    const blob = new Blob([fileData], { type: "application/msword" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "文章.docx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const setEditContent = (index, content) => {
    // console.log("🚀 ~ setEditContent ~ content:", index, content);

    let tempMsg = [...currentMsg];
    tempMsg[index].data = content;
    const data = { ai_id: wholeItem.id, content: JSON.stringify(tempMsg) };
    // console.log("🚀 ~ setEditContent ~ tempMsg:", data);
    setIsClickEdit(true);
    // // console.log(wholeItem);
    saveDetail(data).then((res) => {
      if (res.code == 0) {
        // console.log("📕--------------", res);

        setCurrentMsg(tempMsg);
      } else {
        message.error(res.msg);
      }
    });

    // console.log(content);
  };
  const compareTexts = (index, item) => {
    // console.log("🚀 ~ compareTexts ~ index:", cacheMsg, index, item);
    if (cacheMsg) {
      cacheMsg[index].data = item;
      // console.log("🚀 ~ compareTexts ~ cacheMsg:", cacheMsg);
      cacheMsg = [...cacheMsg];
    }
  };

  return (
    <div className="chatFlowItem robot" id={sId}>
      {/* {aiStatus.messageInfo.isSending ? "开" : "关"} */}
      <div className="avatar">
        {aiStatus.messageInfo.isSending &&
          aiStatus.messageInfo.msgId === sId && (
            <Spin
              className="loadingAvatar"
              indicator={
                <LoadingOutlined
                  style={{ width: "100%", height: "100%" }}
                  spin
                />
              }
            />
          )}
      </div>
      <div className="chatContent">
        {currentMsg &&
          currentMsg.map((sitem, sindex) => {
            // console.log("🚀 ~ currentMsg.map ~ sitem:", sitem);
            // 外挂组件专用
            const Component = Plugins[sitem?.return_type] || "";
            // mardown 组件专用

            // 如果是(1、非markdown类型,2、推荐类型除外，3、最后一个消息，4、且是在开启了打字）则关闭打字。
            if (
              ["error"].includes(sitem?.return_type) &&
              aiStatus.messageInfo.msgId === sitem?.id &&
              aiStatus.messageInfo.isSending
            ) {
              eventBus.$emit("AIStatus", {
                messageInfo: {
                  msgId: "",
                  isSending: false,
                },
              });
            }
            const markdownView =
              [
                "chat",
                "doi",
                "author",
                "user_idea",
                "innovation_idea",
                "summary",
                "result_data",
                "new_article",
                "reference", // 添加引用
                "method", // 创新方法
                "patent",
                "old_article",
              ].includes(sitem?.return_type) || false;

            // 生成配方专用
            const genFormula = sitem?.return_type === "ff" || false;

            // 推荐文本专用
            const suggestView =
              ["suggest", "suggest-common"].includes(sitem?.return_type) ||
              false;

            const suggestViewLink =
              ["suggest_link"].includes(sitem?.return_type) || false;
            // 异常文本专用
            const errorView = sitem?.return_type === "error" || false;
            // 作者文章 来国桥
            const showAuthorArticle =
              ["author_article", "score"].includes(sitem?.return_type) || false;
            // 临时展示
            const infoView = ["info"].includes(sitem?.return_type) || false;
            // 提醒展示
            const warningView =
              ["warning"].includes(sitem?.return_type) || false;

            // console.log(
            //   "🚀 ~ {itemResParse.map ~ item:",
            //   sitem,
            //   Plugins,
            //   Component
            // );
            return (
              <Fragment
                key={sId + sindex + ""}
                // className={
                //   sitem.return_type == "whole_article" ||
                //   sitem.return_type == "whole_new_article"
                //     ? "chatText chatTextNobg"
                //     : sitem.return_type == "new_article"
                //     ? "chatText newArticle"
                //     : "chatText"
                // }
              >
                {/* 处理 动作的组件专用 */}
                {Component && (
                  <div className="chatText">
                    <Component aiStatus={aiStatus} content={sitem.data} />
                  </div>
                )}
                {/* 处理聊天对话的内容 */}
                {!isEdit[sindex] && markdownView && (
                  <div
                    className={
                      sitem.return_type == "whole_article" ||
                      sitem.return_type == "whole_new_article"
                        ? "chatText chatTextNobg"
                        : sitem.return_type == "new_article"
                        ? "chatText newArticle"
                        : "chatText"
                    }
                  >
                    <Markdown
                      compareTexts={compareTexts}
                      sourceData={sitem}
                      sindex={sindex}
                      content={sitem.data}
                      returnType={sitem.return_type}
                      aiStatus={aiStatus}
                      sId={sId}
                    />
                  </div>
                )}
                {/* 编辑内容 */}
                {isEdit[sindex] && markdownView && (
                  <div className="chatText">
                    <MarkdownEditor
                      content={sitem.data}
                      aiStatus={aiStatus}
                      setEditContent={setEditContent}
                      sindex={sindex}
                    />
                  </div>
                )}

                {infoView && <InfoView content={sitem}></InfoView>}
                {warningView && <WarningView content={sitem}></WarningView>}

                {showAuthorArticle && (
                  <AuthorArticleView
                    content={sitem}
                    sendMessage={sendMessage}
                  ></AuthorArticleView>
                )}
                {/* 生成配方 */}
                {genFormula && (
                  <GeneFormulaFile
                    formulaFiles={sitem.data}
                    sendMessage={sendMessage}
                  />
                )}
                {/* 推荐 链接*/}
                {suggestViewLink && (
                  <div className="suggestText ">
                    <div
                      type="primary"
                      ghost
                      style={{
                        color: "#1677ff",
                        cursor: "pointer",
                        padding: 5,
                      }}
                      // className="longTextShowOneLine"
                      onClick={() => {
                        window.open(sitem?.data, "_blank");
                      }}
                    >
                      {sitem.data}
                      <i className="iconfont icon-fabanguanlih"></i>
                    </div>
                  </div>
                )}
                {/* 推荐 文本*/}
                {suggestView && (
                  <SuggestView
                    content={sitem}
                    sendMessage={sendMessage}
                  ></SuggestView>
                )}

                {/* 异常 */}
                {errorView && (
                  <div className="chatText">
                    {sitem.data}
                    <i
                      className="iconfont icon-shuaxin1 reload"
                      onClick={() => {
                        let itemReqParse = JSON.parse(
                          wholeItem?.reqContent || "{}"
                        );

                        sendMessage(itemReqParse);
                      }}
                    ></i>
                  </div>
                )}

                {/* 处理各对话场景下的不同的功能按钮 */}
                {(sitem?.data ||
                  sitem.return_type == "whole_article" ||
                  sitem.return_type == "whole_new_article") &&
                  Component === "" && (
                    <OperaButtons
                      sindex={sindex}
                      isEdit={isEdit}
                      aiStatus={aiStatus}
                      setIsEdit={setIsEdit}
                      setDownloadFile={setDownloadFile}
                      sitem={sitem}
                      currentId={wholeItem.id}
                      sendMessage={sendMessage}
                      currentMsg={currentMsg}
                    />
                  )}
                {/* <Button>创新方法</Button> */}
              </Fragment>
            );
          })}

        {msg?.url && (
          <a href={msg?.url} target="_black" className="link">
            {msg?.url}
          </a>
        )}
        {/* <div className="chatAction">
          <Space>
            <Button  type="primary">
              编辑
            </Button>
            <Button  type="primary">
              下载
            </Button>
          </Space>
        </div> */}
      </div>

      {aiStatus.messageInfo.isSending &&
        aiStatus.messageInfo.msgId === sId &&
        userInfo?.id !== sId && (
          <div
            style={{
              padding: "10px 52px",
              display: "inline-block",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                eventBus.$emit("AIStatus", {
                  messageInfo: {
                    msgId: "",
                    isSending: false,
                  },
                });
              }}
            >
              停止生成
            </Button>
          </div>
        )}
    </div>
  );
}
